import type { ReactNode } from "react";

import { Tooltip } from "@repo/shared-components/react";

import { Type } from "@repo/nerdwallet-components";

import styles from "./DriverCell.module.less";

interface DriverCellProps {
  description?: string;
  tooltip: any;
  value: string | ReactNode;
  valueTooltip?: string;
  children?: any;
}

const DriverCell = ({
  description,
  tooltip,
  value,
  valueTooltip,
  children,
}: DriverCellProps) => {
  if (children) return children;

  return (
    <div style={{ position: "relative" }}>
      <span>{value}</span>
      {tooltip && <Tooltip content={valueTooltip} />}
      <Type color="neutral-darker" size="1" className={styles.description}>
        {description}
      </Type>
    </div>
  );
};

export default DriverCell;
