import { Box, Type } from "@repo/nerdwallet-components";
import { ClientOnly } from "@repo/shared-components/react";

import QuestionMark from "./QuestionMark.svg";
import SoftwareGreenMark from "./softwareGreenMark.svg";

import styles from "./FactCheckTooltip.module.less";

const FactCheckTooltip = ({ isSoftware2025 }: { isSoftware2025?: boolean }) => {
  return (
    // this div is necessary so Type can be position:absolute and the content won't shrink
    <div>
      <Type
        type="button"
        aria-label="Fact check tooltip"
        className={styles.trigger}
      >
        <Box display="flex" flexDirection="row">
          {isSoftware2025 ? (
            <div className={styles.softwareFactCheckPill}>
              <span className={styles.softwareCheck}>
                <img
                  src={SoftwareGreenMark.src}
                  alt="Green success icon"
                  height={16}
                  width={16}
                  className={styles.questionMarkIcon}
                />
              </span>
              Fact checked and reviewed
            </div>
          ) : (
            <div className={styles.factCheckPill}>
              <span className={styles.check}>✅</span>Fact checked and reviewed
            </div>
          )}
          <img
            src={QuestionMark.src}
            alt="Question mark icon"
            height={13}
            width={13}
            className={styles.questionMarkIcon}
          />
        </Box>
        <ClientOnly>
          <div className={styles.buffer} />
          <Type
            component="span"
            className={styles.hoverContent}
            aria-label="Fact check content tooltip"
          >
            <Type component="span" className={styles.arrow} aria-hidden />
            <Box display="flex" flexDirection="column" gridGap={2}>
              <Type component="p" bold color="green-dark" size="1">
                <span className={styles.check}>✅</span> Fact-checked and
                reviewed
              </Type>

              <Type component="p" size="1">
                NerdWallet's content is{" "}
                <Type component="span" bold size="1">
                  fact-checked for accuracy, timeliness, and relevance by humans
                </Type>
                . It undergoes a thorough review process involving writers and
                editors to ensure the information is as clear and complete as
                possible. Learn more by checking our{" "}
                <Type
                  component="a"
                  size="1"
                  target="_blank"
                  href="https://nerdwallet.com/l/editorial-guidelines"
                >
                  Editorial Guidelines
                </Type>
                .
              </Type>

              <Type
                component="p"
                size="0"
                className={styles.tooltipContentFooter}
              >
                Content was accurate at the time of publication.
              </Type>
            </Box>
          </Type>
        </ClientOnly>
      </Type>
    </div>
  );
};

export default FactCheckTooltip;
