import type { ReactElement } from "react";
import classNames from "classnames";

import { Tabs } from "@repo/nerdwallet-components";

import styles from "./CardTableDetails.module.less";

interface CardTableDetailsProps {
  heading?: string;
  fullWidth?: boolean;
  tagline?: ReactElement;
  prosAndCons?: ReactElement;
  whyWeLikeIt?: ReactElement;
  qualifications?: ReactElement;
  tableType?: string;
}

const CardTableDetails = ({
  heading,
  fullWidth,
  tagline,
  prosAndCons,
  whyWeLikeIt,
  qualifications,
  tableType,
}: CardTableDetailsProps) => {
  const tabs = [
    prosAndCons?.props?.value &&
      ({ label: "Pros & Cons", content: prosAndCons } as any),
    whyWeLikeIt?.props?.value && {
      label: "Why we like it",
      content: whyWeLikeIt,
    },
    tagline?.props?.value && { label: "Why we like it", content: tagline },
    qualifications?.props?.value && {
      label: tableType === "CC Summary" ? "Product Details" : "Qualifications",
      content: qualifications,
    },
  ].filter(Boolean);

  return (
    <div
      className={classNames(styles.details, { [styles.fullWidth]: fullWidth })}
    >
      <div>
        <Tabs aria-label={heading} tabs={tabs} />
      </div>
    </div>
  );
};

export default CardTableDetails;
