import { type ReactNode } from "react";

import classNames from "classnames";

import { Box, Text } from "@repo/nerdwallet-components";

import { Carousel } from "@repo/shared-components/react";

import styles from "./FunderaTestimonials.module.less";

interface Props {
  centerTitle?: boolean;
  heading?: string;
  slides?: { id: string; title: string; description: string }[];
  trustpilotStars?: ReactNode;
  trustpilotLogo?: ReactNode;
}

const FunderaTestimonials = ({
  trustpilotStars,
  trustpilotLogo,
  centerTitle,
  heading = "Testimonials",
  slides = [
    {
      id: "application",
      title: "Osamede Osa Imasuen",
      description:
        '"I was on the market for small business funding to help expand my business and they were able to get me funded in record time, with a great amount and better rates than I was getting with other companies."',
    },
    {
      id: "review",
      title: "Victor Zeines",
      description:
        '"Went above and beyond what all the other companies were able to do. They spent the time to figure out a way work with the other two credit reporting companies."',
    },
    {
      id: "choose",
      title: "Troy Nelson",
      description:
        '"I have to mention the application to funding process happened more quickly than I would have ever thought possible."',
    },
  ],
}: Props) => {
  return (
    <div className={styles.container}>
      {heading && (
        <Box
          className={classNames({
            [styles.center]: centerTitle,
          })}
        >
          <Text component="h2" size="large" bold>
            {heading}
          </Text>
        </Box>
      )}
      <div className={styles.carousel}>
        <Carousel
          arrows
          dots
          dotsClass={classNames("slick-dots", styles.dots)}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                arrows: false,
              },
            },
          ]}
        >
          {slides.map(({ id, title, description }) => {
            return (
              <div key={id}>
                <div className={styles.item}>
                  <div className={styles.itemContent}>
                    <div className={styles.description}>
                      <Text>{description}</Text>
                    </div>
                    <div className={styles.titleContainer}>
                      <Text bold>{title}</Text>
                    </div>
                    <div className={styles.stars}>{trustpilotStars}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className={styles.trustpilotWrapper}>
        <div>See all reviews on</div>
        <div className={styles.trustpilotLogo}>{trustpilotLogo}</div>
      </div>
    </div>
  );
};

export default FunderaTestimonials;
